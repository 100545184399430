body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* breakpoints */

@media screen and (max-width: 896px) {
  body {
    font-size: 10px !important;
  }

  .navTitles {
    font-size: 12px !important;
  }

  .navMenu {
    padding-right: 1.5em !important;
  }

  .firstName {
    font-size: 7em !important;
  }

  .lastName {
    font-size: 8em !important;
  }

  .title {
    font-size: 12px !important;
    font-family: Cool;
    position: absolute;
    top: 340px !important;
    left: 200px !important;
    color: #e7ddeb
  }

  .divider {
    size: 0px;
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 50% !important;
    padding-top: 10px;
    left: 0% !important;
  }

  .icon {
    height: 15px !important;
    -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
    padding-right: 0px !important;
  }

  .arrow {
    height: 20px !important;
    width: 20px !important;
    color: antiquewhite !important;

  }

  .aboutDiv {
    background-image: url(/static/media/by-the-pool-14.25a4be2e.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 130em !important;
    /* padding: 50px; */
  }
  
  .me {
    height: 200px !important;
    width: 300px !important;
    border-radius: 10% 30% 50% 70%;
  }

  .diverImage {
    height: 200px !important;
    margin-top: 210px;
    margin-right: 300px !important;
  }

  .diver {
    height: 80px !important;
    margin-top: 400px;
  }

  .aboutIcon {
    height: 1em !important;
    width: 1em !important;
    padding: 2px !important;
  }

  .aboutIconButton {
    size: 8em !important;
  }

  .bioDiv {
    text-align: center;
    font-size: 5px;
    padding-left: 5px !important;
  }

  .bio {
    padding: 5px !important;
    margin-right: 290px;
    margin-top: 15px;
    text-align: center;
    color: antiquewhite !important;
  }
  
  .aboutMe {
    padding-top: 2em !important;
    padding-left: 18px !important;
    font-size: 2em !important;
    color: antiquewhite !important;

  }
  
  .aboutMeDiv {
    padding-left: 0em !important;
    display: block;
  }

  .aboutIconDiv {
    padding-left: 0px !important;
    display: inline-flex !important;
  }

}

/* fonts */

@font-face {
  font-family: NT;
  src: url(/static/media/NT.ffc9c569.otf);
}

@font-face {
  font-family: Argesta;
  src: url(/static/media/argestadisplay-regular-webfont.6e7c0fbe.ttf);
}

@font-face {
  font-family: North;
  src: url("/static/media/North Carossela.36914708.otf");
}

@font-face {
  font-family: Sek;
  src: url(/static/media/Sekogure.18ce37a1.ttf);
}

@font-face {
  font-family: Made;
  src: url(/static/media/MADEOkineSansPERSONALUSE-Medium.e3771b16.otf);
}

@font-face {
  font-family: Stretch;
  src: url(/static/media/StretchPro.61072ed9.otf);
}

@font-face {
  font-family: Cool;
  src: url("/static/media/coolvetica rg.651b51cc.otf");
}

@font-face {
  font-family: Avi;
  src: url(/static/media/Avigea.7f5142ad.ttf);
}

/* general */

body {
  background-color: #1b3d25 !important;
  color: #e7ddeb !important;
}

div {
  font-family: Argesta;
}

a {
  color: #e7ddeb;
}

.spacer {
  padding: 1em;
}

.pageTitle {
  font-family: Cool;
  font-size: 5em;
  margin-bottom: 10px;
}

.pageContainer {
  padding: 40px;
}

.layer {
  pointer-events: auto;
  z-index: -1;
}

/* LandingLayout */

.firstName {
  font-family: NT;
  font-size: 10em;
  color: #e7ddeb !important;
}

.lastName {
  font-family: NT;
  font-size: 10em;
  padding-left: 18%;
  color: #e7ddeb !important;
}

.title {
  font-family: Cool;
  font-size: 2em;
  position: absolute;
  top: 75%;
  left: 40%;
  color: #e7ddeb
  
}

.divider {
  border: 20px solid #312c2d;
  padding: 10px;
  padding-top: 39px;
}

.icons {
  position: fixed;
  top: 9.5%;
  left: 96%;
  padding-top: 10px;
}

.icon {
  width: 40px;
  height: 40px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  padding-right: 10px;

}

.iconSpacer {
  padding: 12px;
}

/* Blogs */

.blog-row {
  flex-wrap: inherit !important;
}

@media screen and (max-width: 768px) {
  .blog-row {
    flex-wrap: wrap !important;
  }
}

.blogText {
  color: black;
  height: 38em !important;
}

.blogTitle {
  font-family: Made;
  color: black;
  font-weight: 900;
  font-size: 23px !important;
}

.blogImage {
  height: 27em !important;
}


/* NavBar */

.navTitles {
  color: #e7ddeb !important;
}

.navMenu {
  font-family: Made;
  font-weight: lighter;
  font-size: 1.5em;
  padding-right: 4em;
  padding-top: 10px;
}

.navLink:hover {
  color: red;
}

nav a:hover {
  text-decoration: underline;
}

/* About */

.aboutDiv {
  background-image: url(/static/media/by-the-pool-14.25a4be2e.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 160em !important;
  /* width: 110em; */
  padding: 50px;
}


.me {
  height: 30%;
  width: 50%;
  border-radius: 10% 30% 50% 70%;
}

.scrollAbout {
  font-family: Stretch;
  font-size: 2em;
}

.bio {
  font-family: Cool;
  font-size: 2em;
  color: black;
  padding: 100px;
}

.arrow {
  height: 50px;
  width: 50px;
  color: black;
}

.bioDiv {
  text-align: end;
  padding-left: 35em;
  width: 100em;
}

.aboutMe {
  font-family: Cool;
  padding-top: 2em;
  padding-left: 3em;
  font-size: 2em;
  color: black;
}

.aboutMeDiv {
  padding-left: 55em;
  display: block;

}

.aboutIcon {
  height: 10em;
  width: 10em;
  padding: 40px;
  color: antiquewhite;
  
}

.aboutIconButton {
  padding: 5px;
  cursor: pointer;
}

.aboutIconSpacer {
  padding-left: 1em;
}

.aboutIconDiv {
  display: flex;
  justify-content: center;}


.connect {
  font-family: Cool;
  text-align: center;
  font-size: 6em;
  color: #1b3d25 !important;
} 

.arrows2 {
  padding-left: 50em;
  padding-top: 12em;
}

.diverImage {
  /* margin-top: 2em; */
  margin-right: 40em;
  height: 30em;
  width: 20em;
}

.diver {
  height: 30em;
  width: 20em;
  float: left;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

/* Portfolio */

.portfolioIcon {
  height: 50px;
  width: 50px;
}

.portfolioIconSpacer {
  padding: 20px;
  font-weight: bold
}

.iconDiv {
  padding: 20px;
  margin-top: 50px !important;
}

.resumeText {
  font-family: Made;
  color:antiquewhite; 
  font-size: 1em;
}

.downloadButton {
  margin-top: 20% !important;
  display: block;
  width: 126px;
  height: 180px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40% !important;
  background-color: #5C106C !important;
  border-color: #E0A0ED !important;
}

.iconCol {
  text-align: center;
}

.ecomText {
  text-align: center;
  padding-left: 50%;
  background-image: url(/static/media/ecom1.6111e28a.png);
  height: 100%;
}

.ecom2 {
  padding: 50px;
  width: 100%;
  height: 90%;
}

.ecomMainImage {
 width: 100%;
}

.listTitle {
  font-weight: bold;
  padding: 5px;
  font-size: 1.5em;
  text-decoration: underline;
}

.projectTitle {
  font-family: Made;
  font-size: 4em;
  padding-bottom: 10px;
}

.projectTextItem {
  font-size: 1.5em;
}

.github {
  width: 40px;
  height: 40px;
}

.githubLink {
  margin-top: 6em !important;

}

.ecomDescriptionDiv {
  height: 50em;
}

.farmText {
  text-align: center;
  padding-left: 50%;
  background-image: url(/static/media/ranch1.7869003d.png);
  height: 100%;
}

.ranchLarge {
  height: 50em;
  padding-left: 20em;
  padding-top: 5em;
}

.ranchSmall {
  height: 30em;
}

.githubButton {
  font-family: Stretch;
  margin-top: 25% !important;
  display: block;
  width: 126px;
  height: 180px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40% !important;
}

.findMore {
    font-family: Cool;
    font-size: 5em;
    margin-top: 3em;
}












